.about-route .container {
    padding: 1px 120px 90px;
    font-weight: 600;
}

.about-route img {
    width: 100%;
    height: auto;
}

.about-route .container>h3 {
    font-size: 22px;
    color: #0C8611;
}

.about-route .container>p {
    color: rgba(58, 58, 58, 0.75);
    font-weight: 400;
    font-size: 1rem;
}

.about-route .container>h2 {
    font-size: 28px;
    color: #3a3a3a;
}

.mvm-statement {
    display: flex;
    gap: 24px;
    margin: 50px auto 50px;
}

.mvm-statement>div {
    background: rgba(196, 196, 196, 0.1);
    width: 384px;
    /* height: 230px; */
    padding: 1rem;
}

.mvm-statement>div h3 {
    color: #3a3a3a;
    font-size: 22px;
}

.mvm-statement>div p {
    font-weight: 400;
    font-size: 1rem;
    color: rgba(58, 58, 58, 0.75);
}


@media screen and (max-width:580px) {
    .about-route .container{
        padding: 1rem;
    }

    .mvm-statement{
        flex-direction: column;
    }

    .mvm-statement>div{
        width: 90%;
    }

}