.services{
    padding: 3.75rem 1rem 4.75rem 7.5rem;
}

.services h3,
.services h2 {
    text-align: center;
    font-weight: 600;
}

.services h3 {
    font-size: 1.4rem;
    color: #0C8611;
}

.services h2 {
    color: #3A3A3A;
    font-size: 1.75rem;
}

.service-container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@media screen and (max-width:580px) {

    .services{
        padding: 1rem;
    }
    .service-container {
        flex-direction: column;
    }
}