.contact {
    display: flex;
    align-items: center;
    margin-bottom: 43px;
}

.contact-details {
    font-weight: 600;
    background-color: rgba(208, 244, 209, 0.3);
    padding: 0 40px 53px 120px;
}

.contact-details h3 {
    color: #0C8611;
    font-size: 22px;
}

.contact-details h2 {
    font-size: 28px;
    color: #3A3A3A;
}

.contact-details p {
    font-weight: 400;
    font-size: 1rem;
    color: #3A3A3ABF;
}

.contact-details section > h3{
    margin-bottom: -0.7rem;
}

.contact-details section div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.contact-details section div > a {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 5px;
    color: #0FA716;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-details section div main > h5 {
    margin-bottom: 0.5px;
    color: #3A3A3A;
    font-weight: 600;
    font-size: 1rem;
}

.contact-details section div main > a{
    font-weight: 400;
    font-size: 1rem;
    color: #3A3A3ABF;
    text-decoration: none;
}

.contact-form{
    background-color: #FFFFFF;
    padding: 32px 98px;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 544px;
}

.contact-form form input,
.contact-form form textarea{
    background-color: #D0F4D14D;
    border-radius: 8px;
    border: none;
    padding: 1rem;
}

.contact-form form textarea{
    height: 116px;
}

.form-button{
    margin-top: 24px;
    padding: 13px 14px;
}

@media screen and (max-width:580px) {
    .contact {
        flex-direction: column;
    }

    .contact-details,
    .contact-form{
        padding: 1rem;
    }

    .contact-form{
        width: 90%;
    }

    .contact-form form{
        width: 100%;
    }
}