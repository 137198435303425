.blog{
    margin: 90px auto 82px;
    padding: 10px 95px 10px 120px;
}

.blog > h3{
    font-weight: 600;
    font-size: 22px;
    color: #0C8611;
}

.blog > section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog > section p{
    font-weight: 400;
    font-size: 1rem;
    color: #3A3A3ABF;
    width: 740px;
}

.blog > section .view-all{
    color: #662D02;
    font-weight: 600;
    font-size: 18px;
}

/* blog card component styling */

.card{
    width: 354px;
    height: 535px;
    box-shadow: 0px 4px 20px 0px #00000026;
    border-radius: 8px;
}

.card .img-div{
    width: 354px;
    height: 251px;
    border-radius: 8px 8px 0px 0px;
}

.card .img-div img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.card .content-div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: auto;
    width: 100%;
    padding: 24px;
}

.card .content-div span:nth-of-type(1){
font-weight: 400;
font-size: 1rem;
color: #3A3A3ABF;
}
.card .content-div span:nth-of-type(2){
    font-weight: 600;
    font-size: 22px;
    color: #3A3A3A;
    width: 85%;
}
.card .content-div span:nth-of-type(3){
    font-weight: 400;
    font-size: 1rem;
    color: rgba(58, 58, 58, 0.75);
    width: 85%;
    padding-bottom: 1rem;
}

/* blog card component */

.blog-cards{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    padding-right: 5px;
}

@media screen and (max-width: 580px) {
    .blog{
        padding: 1rem 1rem 3rem;
        position: relative;
        margin: 48px auto 38px;
    }

    .blog > section .view-all{
        position: absolute;
        bottom: 0px;
        right: 25px;
    }

    .card{
        width: 100%;
        margin: auto;
        text-overflow: ellipsis;
    }

    .card .img-div{
        width: 100%;
    }

    .card .content-div{
        padding: 10px 0 0 12px;

    }

    .card .content-div span:nth-of-type(3){
        overflow: hidden;
        word-wrap: break-word;
        object-fit: contain;
        word-wrap: break-word;
        height: 114px;
        
    }
}