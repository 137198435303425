.services-card{
    display: flex;
    flex-direction: column;
    background-color: rgba(196, 196, 196, 0.1);
    padding: 1rem;
    width: 20rem;
    height: 14.25rem;
}

.services-card span{
background-color: #D0F4D1;
font-size: 1.5rem;
width: 2rem;
padding: 0.7rem;
display: inline-flex;
align-items: center;
justify-content: center;
}

.services-card h3{
    font-weight: 600;
    font-size: 1.38rem;
    color: #3A3A3A;
    text-align: left;
    margin-bottom: 0.5px;
}

.services-card p{
    font-weight: 400;
    font-size: 1rem;
    color: rgba(58, 58, 58, 0.75);
}

@media screen and (max-width: 580px) {

    .services-card{
        text-align: center;
        width: 85%;
        margin: auto;
        height: auto;
    }
    .services-card span{
        margin: auto;
    }

    .services-card h3{
        text-align: center;
    }

}