.about-us {
    display: flex;
    padding: 10.3rem 2.5rem 2.5rem 7.5rem ;
    align-items: center;
    gap: 7.5rem;
}

.about-lhs {
    position: relative;
}

.about-lhs .box {
    position: absolute;
    height: 130px;
    width: 141px;
    z-index: -1;
}

.about-lhs .brown {
    background-color: #662D02;
    left: 441px;
    top: -40px;
}

.about-lhs .green {
    background-color: #09650D;
    left: -65px;
    top: 300px;
}

.mandate-text h3 {
    font-weight: 600;
    font-size: 1.4rem;
    color: #0C8611;
    line-height: 0.5;
}

.mandate-text h2 {
    color: #3A3A3A;
    font-weight: 600;
    font-size: 1.75rem
}

.mandate-text p{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #3A3A3ABF;

}

.about-us-button {
    padding: 13px 49px;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2rem;
    cursor: pointer;
}

@media screen and (max-width: 580px) {
    .about-us {
        flex-direction: column;
        padding: 3.9rem 1rem;
        gap: 4rem
    }

    .about-lhs img{
        width: 100%;
        display: block;
        margin: auto;
    }

    .about-lhs .brown {
        left: 187px;
        top: -40px;
    }
    
    .about-lhs .green {
        left: 1px;
        top: 140px;
    }

    .mandate-text p{
        line-height: 1.8;
    }

    .about-us-button{
        margin-top: 1rem;
    }
}