footer {
  background-color: #0c8611;
  color: #ffffff;
  padding: 40px 90px 50px 120px;
}

.footer {
  display: flex;
  gap: 14.25rem;
}

.footer .lhs-footer {
  width: 24rem;
}

.footer .lhs-footer p {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffffbf;
  line-height: 1.7;
}

.footer .lhs-footer span {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.footer .rhs-footer {
  display: flex;
  justify-content: space-between;
  gap: 7rem;
}

.footer .rhs-footer section {
  display: grid;
  /* flex-direction: column; */
}

.footer .rhs-footer section h4 {
  font-weight: 600;
  font-size: 1.125rem;
  color: #ffffff;
}

.footer .rhs-footer section span {
  font-weight: 400;
  font-size: 1rem;
  color: #ffffffbf;
  line-height: 2;
  flex: 1 0 0;
}

.footer .rhs-footer section .footer-link {
  font-weight: 400;
  font-size: 1rem;
  color: #ffffffbf;
  line-height: 2;
  text-decoration: none;
}

footer > p {
  text-align: right;
  margin-top: 75.5px;
  font-weight: 400;
  font-size: 14px;
}

@media screen and (max-width: 580px) {
  footer {
    padding: 1rem;
  }

  .footer {
    flex-direction: column;
    gap: 1rem;
  }

  .footer .lhs-footer {
    width: 100%;
  }

  .footer .rhs-footer {
    flex-direction: column;
    gap: 1rem;
  }
  .footer .lhs-footer span {
    font-size: 2rem;
  }

  footer > p {
    text-align: center;
    font-size: 13px;
  }
}
