.portal{
   background-color: rgba(208, 244, 209, 0.3);
   padding: 20px 120px 40px;
   margin: auto;
}

.portal-button{
   padding: 13px 30px;
   margin: 1rem;
   cursor: pointer;
   transition: all 2s ease;
}

.active-portal.active{
   padding: 20px 2px;
   border-bottom: 3px solid #662D02;

}

@media screen and (max-width: 580px) {
   .portal{
      padding: 1rem;
   }
}